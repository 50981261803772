@import "./colors";

$font-family-primary: "Inter";

//Border Radius
$radius4: 4px;
$radius6: 6px;
$radius8: 8px;
$radius10: 10px;
$radius12: 10px;
$radius20: 20px;
$round-radius: 50px;
$circle-radius: 50%;
$global-radius: 4px;

// $border: 1px solid $border-primary;
// $border-light: 1px solid $border-secondary;
// $border-ex-light: 1px solid $border-tertiary;

//layouts
$header-height: 64px;
$org-login-width: 436px;
$sidebar-width: 200px;

//App Filter
$header-blur: blur(10px);
$backdrop-blur: blur(5px);

//Container sizes
$container-width: 1280px;

@media (min-width: 1270px) {
  $container-width: 1180px;
}

@media (min-width: 1024px) and (max-width: 1270) {
  $container-width: 1024px;
}
