@use "./settings/" as *;

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 998;
    background: var(--modal-opacity) !important;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    .ReactModal__Content {
      padding: 0;
      max-width: 400px;
      height: max-content;
      background: none;
      margin: 0 auto;
      background: transparent !important;
      border: none !important;
    }
  }
}
