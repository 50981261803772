@use "./settings/index" as *;

* {
  font-family: $font-family-primary !important;
  box-sizing: border-box;
}

html {
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
}

body {
  background: var(--black) !important;
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-family: $font-family-primary !important;
  color: var(--text-primary);
  overflow-x: hidden;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
}

html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
p {
  margin: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0;
  outline: none;
  color: var(--text-1);
  overflow-wrap: break-word;
}

button {
  outline: none;
  cursor: pointer;
  outline-color: transparent;
  appearance: none;
}
button:focus,
button > .focus {
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

::selection {
  color: var(--primary);
  background: var(--primary-bg-opacity-1);
}

body.light-mode {
  ::selection {
    color: var(--secondary);
    background: var(--white-opacity-1);
  }
}

//App Classes

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.appearance-none {
  @include appearance(none);
}

.img-max-width {
  max-width: 100%;
}
.img-max-height {
  max-height: 100%;
}
.radius-circle {
  border-radius: 100%;
}
.radius-half {
  border-radius: 50%;
}

.disabled {
  cursor: not-allowed;
  @include userSelect(none);
}
.text-disabled {
  color: var(--text-hint);
  @include userSelect(none);
  cursor: not-allowed;
}

.overflow-hidden {
  overflow: hidden;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}

//Status section
.status-icon {
  display: grid;
  place-items: center;
  @include size(60px);
  border-radius: $round-radius;
  .MuiSvgIcon-root {
    font-size: 30px;
  }
  &.success {
    background: var(--success-opacity-15);
    .MuiSvgIcon-root {
      color: var(--success);
    }
  }
  &.error {
    background: var(--error-opacity-15);
    .MuiSvgIcon-root {
      color: var(--error);
    }
  }
}

//App Container

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  &.from-container {
    max-width: 800px;
  }
  &.app-container {
    max-width: 1100px;
  }
  &.form-container {
    max-width: 600px;
  }
  &.not-found-container {
    max-width: 868px;
  }
}

@include medium-device-below {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

//Settings cards scroll
.settings-cards-scroll {
  max-height: 350px;
  overflow: auto;
}
