@use "./settings/" as *;

.footer-section {
  border-top: 0.5px solid var(--border-tertiary);
  .email-bg {
    position: relative;
    overflow: hidden;
    border: 2px solid var(--bg-light-1);
    z-index: 1;
    .mail-bg {
      visibility: hidden;
      @include positionFull(absolute, 0);
      width: 200px;
      height: 200px;
      border-radius: $circle-radius;
      // animation: rotate 3s cubic-bezier(0.12, 0.45, 0.27, 0.99) infinite;
      transform-origin: center;
      z-index: 0;

      svg {
        border-radius: $circle-radius;
      }
    }
    &:hover {
      .mail-bg {
        visibility: visible;
      }
      border: 2px solid var(--bg-light-1);
    }
    &:focus {
      .mail-bg {
        visibility: visible;
      }
      border: 2px solid var(--bg-light-1);
    }
  }
  .social-link {
    position: relative;
    overflow: hidden;
    transition: all 0.3;
    &:hover {
      &::before {
        content: "";
        @include positionFull(absolute, 0);
        margin: auto;
        width: 100%;
        background: var(--bg-secondary-05);
        backdrop-filter: blur(1px);
        z-index: 1;
      }
      &::after {
        content: "";
        @include positionFull(absolute, 0);
        margin: auto;
        width: 32px;
        background: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTcgMTdMMTcgN00xNyA3SDhNMTcgN1YxNiIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPiA8L2c+Cg08L3N2Zz4=)
          center no-repeat;
        background-size: 20px;
        z-index: 2;
        animation: arrowanimation 0.3s linear 1;
        transform: translateX(0);
      }
    }
  }
  .footer-name {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 180px;
      margin: auto;
      width: 100%;
      background: linear-gradient(var(--dark-opacity-3), var(--black));
      z-index: 1;
    }
    .footer-text {
      position: relative;
      top: 20px;
    }
  }
}

@keyframes arrowanimation {
  0% {
    transform: rotate(90deg);
    transform: translateX(-6px);
    opacity: 0.3;
  }
  100% {
    transform: rotate(90deg);

    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rotate {
  50% {
    transform: rotate(90deg);
    opacity: 1;
  }
  75% {
    transform: rotate(100deg);
    opacity: 0.7;
  }
  100% {
    transform: rotate(180deg);
    opacity: 1;
  }
}

//Responsive
@include for-mobile-below {
  .footer-section .footer-name::before {
    bottom: -10px !important;
    height: 66px !important;
  }
  .email-button {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    width: 60px !important;
    height: 60px !important;
    border: 1px solid var(--bg-light-1) !important;
    z-index: 10 !important;
    .mail-bg {
      visibility: visible !important;
      width: 100px !important;
      height: 100px !important;
      .icon-scale svg {
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
}
