.project-section {
  .project-hero-card {
    position: relative;
    height: 500px;
    border-radius: 20px;
    .project-hero-title {
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 160px;
      z-index: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  &.vcx-project {
    .project-hero-card {
      background: url(../../images/projects/vcx/vcx-2.png) top center no-repeat;
      background-size: 100%;
      &::after {
        background: var(--vcx-card-overlap);
      }
    }
    .vcx-userflow {
      background: url(../../images/projects/vcx/vcx-3.png) top center no-repeat;
      background-size: contain;
    }
    .vcx-img1 {
      background: url(../../images/projects/vcx/vcx-4.png) top center no-repeat;
      background-size: cover;
    }
    .vcx-img2 {
      background: url(../../images/projects/vcx/project-1.png) top center no-repeat;
      background-size: cover;
    }
  }
  &.exchange-project {
    .project-hero-card {
      background: url(../../images/projects/exchange/exchange1.png) top center no-repeat;
      background-size: cover;
      // &::after {
      //   background: var(--exchange-card-overlap);
      // }
    }
    .exchange-flow {
      background: url(../../images/projects/exchange/flows.png) center center no-repeat;
      background-size: 70%;
    }
    .exchange-img1 {
      background: url(../../images/projects/exchange/exchange2.png) top center no-repeat;
      background-size: cover;
    }
    .exchange-img2 {
      background: url(../../images/projects/exchange/exchange3.png) top center no-repeat;
      background-size: cover;
    }
    .exchange-img3 {
      background: url(../../images/projects/exchange/exchange4.png) top center no-repeat;
      background-size: cover;
    }
    .exchange-img4 {
      background: url(../../images/projects/exchange/exchange5.png) top center no-repeat;
      background-size: cover;
    }
    .exchange-img5 {
      background: url(../../images/projects/exchange/exchange6.png) top center no-repeat;
      background-size: cover;
    }
    .exchange-img6 {
      background: url(../../images/projects/exchange/exchange7.png) top center no-repeat;
      background-size: cover;
    }
  }
  &.terapool-project {
    .project-hero-card {
      background: url(../../images/projects/terapool/terapool-cover.png) top center no-repeat;
      background-size: cover;
      // &::after {
      //   background: var(--bg-dark-1);
      // }
    }
    .terapool-img1 {
      background: url(../../images/projects/terapool/terapool-1.png) top center no-repeat;
      background-size: cover;
    }
    .terapool-img2 {
      background: url(../../images/projects/terapool/terapool-2.png) top center no-repeat;
      background-size: cover;
    }
    .terapool-img3 {
      background: url(../../images/projects/terapool/terapool-3.png) top center no-repeat;
      background-size: cover;
    }
    .terapool-img4 {
      background: url(../../images/projects/terapool/terapool-4.png) top center no-repeat;
      background-size: cover;
    }
    .terapool-img5 {
      background: url(../../images/projects/terapool/terapool-5.png) top center no-repeat;
      background-size: cover;
    }
    .terapool-img6 {
      background: url(../../images/projects/terapool/terapool-6.png) top center no-repeat;
      background-size: cover;
    }
  }
  &.familytree-project {
    .project-hero-card {
      background: url(../../images/projects/familytree/familytree-cover.png) top center no-repeat;
      background-size: cover;
      &::after {
        background: var(--preserve-card-overlap);
      }
    }
    .familytree-img1 {
      background: url(../../images/projects/familytree/familytree-1.png) top center no-repeat;
      background-size: cover;
    }
    .familytree-img2 {
      background: url(../../images/projects/familytree/familytree-2.png) top center no-repeat;
      background-size: cover;
    }
    .familytree-research-1 {
      background: url(../../images/projects/familytree/analysis-1.png) top center no-repeat;
      background-size: contain;
    }
    .familytree-research-2 {
      background: url(../../images/projects/familytree/alalysis-3.png) center center no-repeat;
      background-size: 80%;
    }
    .familytree-research-3 {
      background: url(../../images/projects/familytree/analysis-2.png) top center no-repeat;
      background-size: contain;
    }
    .familytree-img4 {
      background: url(../../images/projects/familytree/familytree-4.png) top center no-repeat;
      background-size: cover;
    }
    .familytree-img5 {
      background: url(../../images/projects/familytree/familytree-5.png) top center no-repeat;
      background-size: cover;
    }
  }
  &.nft-project {
    .project-hero-card {
      background: url(../../images/projects/nft/nft-cover.png) top center no-repeat;
      background-size: cover;
      // &::after {
      //   background: var(--dark-opacity-3);
      // }
    }
    .nft-img1 {
      background: url(../../images/projects/nft/nft-2.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img2 {
      background: url(../../images/projects/nft/nft-3.png) top center no-repeat;
      background-size: 102%;
    }
    .nft-img3 {
      background: url(../../images/projects/nft/nft-4.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img4 {
      background: url(../../images/projects/nft/nft-5.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img5 {
      background: url(../../images/projects/nft/nft-6.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img6 {
      background: url(../../images/projects/nft/nft-7.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img7 {
      background: url(../../images/projects/nft/nft-6.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img8 {
      background: url(../../images/projects/nft/nft-8.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img9 {
      background: url(../../images/projects/nft/nft-9.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img10 {
      background: url(../../images/projects/nft/nft-10.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img11 {
      background: url(../../images/projects/nft/nft-11.png) top center no-repeat;
      background-size: cover;
    }
    .nft-img12 {
      background: url(../../images/projects/nft/nft-12.png) top center no-repeat;
      background-size: cover;
    }
  }
  &.crm-project {
    .project-hero-card {
      background: url(../../images/projects/crm/crm-cover.png) top center no-repeat;
      background-size: cover;
      &::after {
        background: var(--dark-opacity-3);
      }
    }
    .crm-process-1 {
      background: url(../../images/projects/crm/crm-process-1.png) top center no-repeat;
      background-size: cover;
    }
    .crm-process-2 {
      background: url(../../images/projects/crm/crm-process-2.png) top center no-repeat;
      background-size: cover;
    }
    .crm-process-3 {
      background: url(../../images/projects/crm/crm-process-3.png) top center no-repeat;
      background-size: cover;
    }
    .crm-process-4 {
      background: url(../../images/projects/crm/crm-flow.png) top center no-repeat;
      background-size: cover;
    }
    .crm-img1 {
      background: url(../../images/projects/crm/crm-design-system.png) top center no-repeat;
      background-size: cover;
    }
    .crm-img2 {
      background: url(../../images/projects/crm/crm-wireframe.png) top center no-repeat;
      background-size: cover;
    }
    .crm-img3 {
      background: url(../../images/projects/crm/crm-dashboard.png) top center no-repeat;
      background-size: cover;
    }
    .crm-img4 {
      background: url(../../images/projects/crm/crm-desktop.png) top center no-repeat;
      background-size: cover;
    }
    .crm-img5 {
      background: url(../../images/projects/crm/crm-mobile.png) top center no-repeat;
      background-size: cover;
    }
  }
}

@include for-tab-below {
  .project-section {
    .project-hero-card {
      height: 340px;
      &::after {
        height: 120px;
      }
    }
  }
}

@include for-mobile-below {
  .project-section {
    .project-hero-card {
      height: 200px;
      &::after {
        height: 80px;
      }
    }
  }
}
