@use "./settings/" as *;

//App Pages

.container {
  padding: 0 30px;
  max-width: 1160px;
}
.container-resume {
  padding: 0 20px;
  max-width: 1060px;
}

.hero-section-content {
  position: relative;
  .hero-position-img {
    position: absolute;
    top: -170px;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding-left: 100px;
    background: url(../../images/man-space.gif) center center no-repeat;
    filter: grayscale(100%);
    background-size: contain;
    z-index: 0;
    border-radius: 10px;
    z-index: 1 !important;
  }
}

.oneText {
  .char {
    &:nth-child(1) {
      opacity: 0.9 !important;
    }
    &:nth-child(2) {
      opacity: 0.85 !important;
    }
    &:nth-child(3) {
      opacity: 0.8 !important;
    }
    &:nth-child(4) {
      opacity: 0.75 !important;
    }
    &:nth-child(5) {
      opacity: 0.7 !important;
    }
    &:nth-child(6) {
      opacity: 0.65 !important;
    }
    &:nth-child(7) {
      opacity: 0.6 !important;
    }
    &:nth-child(8) {
      opacity: 0.55 !important;
    }
    &:nth-child(9) {
      opacity: 0.5 !important;
    }
    &:nth-child(10) {
      opacity: 0.45 !important;
    }
    &:nth-child(11) {
      opacity: 0.4 !important;
    }
    &:nth-child(12) {
      opacity: 0.35 !important;
    }
  }
}

.hero-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
  // --dot-size: 1px;
  // --dot-space: 22px;
  // background:
  //   linear-gradient(90deg, var(--black) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center /
  //     var(--dot-space) var(--dot-space),
  //   linear-gradient(var(--black) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space)
  //     var(--dot-space),
  //   var(--dot-bg-color);

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: url(../../images/noice.svg) center center repeat;
  //   background-size: 80px 80px;
  //   z-index: 0;
  //   border-radius: 20px;
  //   opacity: 0.07;
  // }
}

.home-drag {
  position: absolute;
  bottom: 10%;
  right: 10%;
}
.home-drag1 {
  position: absolute;
  top: 20%;
  left: 10%;
}

.content-loading-section {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.creator-section {
  background: var(--bg-primary);
  .creator-cards {
    .creator-card {
      border-radius: $radius20;
      background: var(--bg-primary);
      border: 1px solid var(--bg-secondary);
      transition: all 0.3;
      img {
        width: auto;
        height: 36px;
      }
      &:hover {
        background: var(--bg-secondary);
      }
    }
  }
}

.other-page-section {
  --dot-size: 1px;
  --dot-space: 22px;
  background:
    linear-gradient(90deg, var(--black) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center /
      var(--dot-space) var(--dot-space),
    linear-gradient(var(--black) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space)
      var(--dot-space),
    var(--dot-bg-color);
}

.expert-card {
  position: relative;
  z-index: 1;
  min-height: 250px;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: url(../../images/noice.svg) center center repeat;
  //   background-size: 80px 80px;
  //   z-index: 0;
  //   border-radius: 20px;
  //   opacity: 0.07;
  // }
}

.title-gradient {
  background: var(--text-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: max-content;
}
.title-gradient-top {
  background: var(--text-gradient-top);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.preloader-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 500px;
    height: 500px;
    margin: auto;
    // background: url(../../images/preload.gif) top center repeat;
    // filter: grayscale(1);
    background-size: contain;
    // animation: dropAnimation 2.5s ease-in-out 1;
    transform: rotate(180deg);
    z-index: 1;
  }
}

.drop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 600px;
  height: 600px;
  border-radius: $circle-radius;
  animation: dropAnimation 3s ease-in-out 1;
  background: url(../../images/3d.gif) top center repeat;
  background-size: contain;
  filter: grayscale(1);
  z-index: 2;
}
.text1-reveal {
  transform: translateY(0);
  animation: textAnimation 1s ease-in-out 1;
  opacity: 1;
}
.text2-reveal {
  transform: translateY(0);
  animation: textAnimation 1.5s ease-in-out 1;
  opacity: 1;
}

@keyframes textAnimation {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes dropAnimation {
  0% {
    width: 200px;
    height: 200px;
    opacity: 1;
    transform: rotate(360deg);
  }
}

//About page
.my-image {
  position: relative;
  width: calc(200px + 6px);
  height: calc(200px + 6px);
  &::after {
    content: "";
    width: calc(200px + 6px);
    height: calc(200px + 6px);
    @include positionFull(absolute, 0);
    margin: 0 auto;
    background: var(--about-img-gradient);
    border-radius: 50%;
    z-index: -1;
    animation: animatedgradient 2s infinite linear;
  }
  .imd-drag {
    position: absolute;
    bottom: 14px;
    right: 16px;
  }
}

@keyframes animatedgradient {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//Resume page
.page-summery-text {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 0;
    width: 10px;
    height: 10px;
    background: var(--resume-bg-text);
  }
}

//Responsive

@include only-mobile-device() {
  .preloader-section {
    &::before {
      width: 320px;
      height: 320px;
    }
  }

  .drop {
    width: 300px;
    height: 300px;
  }
}

// Home Page project Section

.home-project-section {
  position: relative;
  overflow: hidden;
  --dot-size: 1px;
  --dot-space: 22px;
  background:
    linear-gradient(90deg, var(--black) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center /
      var(--dot-space) var(--dot-space),
    linear-gradient(var(--black) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space)
      var(--dot-space),
    var(--dot-bg-color);
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 10%;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: url(../../images/bakground-glow.gif) center center no-repeat;
  //   background-size: cover;
  //   z-index: 0;
  //   border-radius: 20px;
  //   opacity: 0.07;
  // }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../images/noice.svg) center center repeat;
    background-size: 80px 80px;
    z-index: 0;
    border-radius: 20px;
    opacity: 0.07;
  }
  .home-project {
    position: relative;
    padding: 20px;
    width: 100%;
    height: 450px;
    border-radius: $radius20;
    background: var(--bg-primary);
    border: 1px solid var(--bg-light-05);
    z-index: 1;
    transition: all 1s;
    &:hover,
    &:focus {
      transform: scale(1.05);
    }
    // &::before {
    //   content: "";
    //   visibility: hidden;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-color: var(--dark-opacity-3);
    //   background: url(../../images/noice.svg) center center repeat;
    //   background-size: 200px 200px;
    //   z-index: 0;
    //   border-radius: 0 0 20px 20px;
    //   -webkit-backdrop-filter: blur(4px);
    //   backdrop-filter: blur(4px);
    //   opacity: 0.05;
    // }
    &::after {
      content: "";
      position: absolute;
      top: 24px;
      right: 30px;
      width: 100px;
      height: 30px;
      z-index: 1;
    }
    &:hover,
    &:focus {
      &::before {
        content: "";
        visibility: visible;
      }
    }
    &.home-crm {
      background: url(../../images/home-project/home-crm.png) center center no-repeat;
      background-size: cover;
      &::after {
        background: url(../../images/logos/logo-crm.png) center center no-repeat;
        background-size: contain;
      }
    }
    &.home-preserve {
      background: url(../../images/home-project/home-preserve.png) center center no-repeat;
      background-size: cover;
      &::after {
        background: url(../../images/logos/logo-preserve.png) center center no-repeat;
        background-size: contain;
      }
    }
    &.home-vcx {
      background: url(../../images/home-project/home-vcx.png) center center no-repeat;
      background-size: cover;
      &::after {
        background: url(../../images/logos/logo-vcx.png) center center no-repeat;
        background-size: contain;
      }
    }
    &.home-exchange {
      background: url(../../images/home-project/home-exchange.png) center center no-repeat;
      background-size: cover;
      &::after {
        background: url(../../images/logos/logo-exchange.png) center center no-repeat;
        background-size: contain;
      }
    }
    &.home-nft {
      background: url(../../images/home-project/home-nft.png) center center no-repeat;
      background-size: cover;
      &::after {
        background: url(../../images/logos/logo-nft.png) center center no-repeat;
        background-size: contain;
      }
    }
    &.home-terapool {
      background: url(../../images/home-project/home-terapool.png) center center no-repeat;
      background-size: cover;
      &::after {
        background: url(../../images/logos/logo-terapool.png) center center no-repeat;
        background-size: contain;
      }
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background: url(../../images/white-dot-grid.svg) top center repeat;
    //   background-size: contain;
    //   z-index: 1;
    // }
  }
}

@include for-tab-below {
  .home-project-section {
    .home-project {
      height: 320px !important;
    }
  }
}

@keyframes backgroundImg {
  0% {
    background-size: cover;
  }
  50% {
    background-size: 110%;
  }
  50% {
    background-size: cover;
  }
}
