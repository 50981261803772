.works-project-card {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.vcx-logo {
    background: url(../../images/logo-bg/vcx-min.png) top center no-repeat;
    background-size: cover;
  }
  &.exchange-logo {
    background: url(../../images/logo-bg/exchange-min.png) top center no-repeat;
    background-size: cover;
  }
  &.nft-logo {
    background: url(../../images/logo-bg/nft-min.png) top center no-repeat;
    background-size: cover;
  }
  &.familytree-logo {
    background: url(../../images/logo-bg/familytree-min.png) top center no-repeat;
    background-size: cover;
  }
  &.crm-logo {
    background: url(../../images/logo-bg/CRM-min.png) top center no-repeat;
    background-size: cover;
  }
  &.terapool-logo {
    background: url(../../images/logo-bg/terapool-min.png) top center no-repeat;
    background-size: cover;
  }
  &.tpay-logo {
    background: url(../../images/logo-bg/tpay-min.png) top center no-repeat;
    background-size: cover;
  }
  &.codepen-logo {
    background: url(../../images/logo-bg/codepen-min.png) top center no-repeat;
    background-size: cover;
  }
  &.figma-logo {
    background: url(../../images/logo-bg/figma-community-min.png) top center no-repeat;
    background-size: cover;
  }
  &.pdf-logo {
    background: url(../../images/logo-bg/pdf-port-min.png) top center no-repeat;
    background-size: cover;
  }
}

@include for-mobile-below {
  .works-project-card {
    width: 100%;
    height: 340px;
    overflow: hidden;
  }
}
